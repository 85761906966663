import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { axiosInstance } from '../../api/apiConfig';
import { Form, Button, Container, Row, Col, Card, Spinner, Alert } from 'react-bootstrap';
import { User, Phone, Calendar, School, Home, Users, CreditCard, Image, ArrowUp, ArrowDown } from 'lucide-react';
import { format } from 'date-fns';
const initialFormState = {
  full_name: '',
  phone_number: '',
  emergency_phone_number: '',
  photo: '',
  id_photo: '',
  gender: '',
  start_date: format(new Date(), 'yyyy-MM-dd'),
  end_date: '',
  registration_number: '',
  year_of_study: 1,
  id_number: '',
  sponsor_phone: '',
  emergency_contact_relationship: '',
  room: '',
  college: '',
  faculty: '',
  terms: false,
};
const OnboardingForm = () => {
  const { companySlug } = useParams();
  const [company, setCompany] = useState(null);
  const [formData, setFormData] = useState({
    full_name: '',
    phone_number: '',
    emergency_phone_number: '',
    photo: '',
    id_photo: '',
    gender: '',
    start_date: format(new Date(), 'yyyy-MM-dd'),
    end_date: '',
    registration_number: '',
    year_of_study: 1,
    id_number: '',
    sponsor_phone: '',
    emergency_contact_relationship: '',
    room: '',
    college: '',
    faculty: '',
    terms: false,
  });

  // Additional states for loading and data
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [rooms, setRooms] = useState([]);
  const [colleges, setColleges] = useState([]);
  const [faculties, setFaculties] = useState([]);
  const [photoPreview, setPhotoPreview] = useState(null);
  const [idPhotoPreview, setIdPhotoPreview] = useState(null);
  const [loadingRooms, setLoadingRooms] = useState(false);
  const [loadingFaculties, setLoadingFaculties] = useState(false);
  const [selectedRoomRate, setSelectedRoomRate] = useState(null);
  const [accountNumber, setAccountNumber] = useState(null);
  // Fetch company information
  useEffect(() => {
    const fetchCompany = async () => {
      try {
        setIsLoading(true);
        const response = await axiosInstance.get(`onboard/api/${companySlug}/`);
        setCompany(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching company:', error);
        setErrorMessage('Could not load company information. Please try again later.');
        setIsLoading(false);
      }
    };

    fetchCompany();
  }, [companySlug]);

  // Fetch rooms when company loads
  useEffect(() => {
    if (company) {
      fetchRooms();
      fetchColleges();
    }
  }, [company]);

  // Fetch faculties when college changes
  useEffect(() => {
    if (formData.college) {
      fetchFaculties(formData.college);
    } else {
      setFaculties([]);
    }
  }, [formData.college]);

  const fetchRooms = async () => {
    try {
      setLoadingRooms(true);
      const response = await axiosInstance.get(`onboard/rooms/${companySlug}/`);
      setRooms(response.data);
      setLoadingRooms(false);
    } catch (error) {
      console.error('Error fetching rooms:', error);
      setLoadingRooms(false);
    }
  };

  const fetchColleges = async () => {
    try {
      const response = await axiosInstance.get(`onboard/get_colleges/`);
      setColleges(response.data);
    } catch (error) {
      console.error('Error fetching colleges:', error);
    }
  };

  const fetchFaculties = async (collegeId) => {
    try {
      setLoadingFaculties(true);
      const response = await axiosInstance.get(`onboard/get_faculties/${collegeId}/`);
      setFaculties(response.data.faculties);
      setLoadingFaculties(false);
    } catch (error) {
      console.error('Error fetching faculties:', error);
      setLoadingFaculties(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    // If the room selection changes, update the room rate
    if (name === 'room' && value) {
      const selectedRoom = rooms.find(room => room.id.toString() === value.toString());
      if (selectedRoom) {
        setSelectedRoomRate(selectedRoom.rate);
      }
    }
  };

  const handleFileChange = async (e, field) => {
    const file = e.target.files[0];
    if (!file) return;

    // Update the formData state with the file
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: file, // Directly assign the file
    }));

    // Set preview for UI feedback
    const previewUrl = URL.createObjectURL(file);
    if (field === 'photo') {
      setPhotoPreview(previewUrl);
    } else if (field === 'id_photo') {
      setIdPhotoPreview(previewUrl);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Ensure terms are accepted
    if (!formData.terms) {
      setErrorMessage('You must agree to the terms and conditions.');
      return;
    }
  
    setIsSubmitting(true);
    setErrorMessage('');
    setSubmitSuccess(false);
  
    try {
      // Submit the form data
      const formDataToSend = new FormData();
      for (const key in formData) {
        if (formData[key] !== null && formData[key] !== undefined) {
          formDataToSend.append(key, formData[key]);
        }
      }
      formDataToSend.append('company', company.slug);
  
      const response = await axiosInstance.post(
        `onboard/api/company/${companySlug}/onboard/student/`,
        formDataToSend,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
  
      // If submission is successful, use the account number from the response
      if (response.status === 201) {
        const { account_number } = response.data; // Extract the account number
        setAccountNumber(account_number); // Store the account number
        setSubmitSuccess(true);
  
        // Scroll to the top
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth scroll to the top
  
        // Reset the form
        setFormData(initialFormState); // Clear the form
        setPhotoPreview(null); // Clear photo preview
        setIdPhotoPreview(null); // Clear ID photo preview
      } else {
        setErrorMessage('Failed to submit form. Please check your information and try again.');
      }
    } catch (error) {
      console.error('Submission error:', error);
      setErrorMessage('Failed to submit form. Please check your information and try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <div className="text-center">
          <Spinner animation="border" role="status" variant="primary" />
          <p className="mt-3">Loading company information...</p>
        </div>
      </Container>
    );
  }

  return (
    <Container className="py-4">
      <Card className="shadow-sm">
        <Card.Header className="bg-secondary text-white">
          <div className="d-flex align-items-center">
            {company?.logo && (
              <img
                src={company.logo}
                alt={company.name}
                className="me-3"
                style={{ height: '50px', maxWidth: '100px', objectFit: 'contain' }}
              />
            )}
            <h2 className="mb-0">{company?.name} student onboarding</h2>
          </div>
        </Card.Header>

        <Card.Body>
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          {submitSuccess && (
            <Alert variant="success">
             <strong>Success!! <br></br> Your account number is: {accountNumber}</strong>
            
            </Alert>
          )}

          <Form onSubmit={handleSubmit} encType="multipart/form-data">
            <h4 className="mb-3">Personal Information</h4>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <User size={16} className="me-2" />
                    Full Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="full_name"
                    value={formData.full_name}
                    onChange={handleInputChange}
                    placeholder="Enter your full name"
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <CreditCard size={16} className="me-2" />
                    ID Number
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="id_number"
                    value={formData.id_number}
                    onChange={handleInputChange}
                    placeholder="Enter your ID number"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <Phone size={16} className="me-2" />
                    Phone Number
                  </Form.Label>
                  <Form.Control
                    type="tel"
                    name="phone_number"
                    value={formData.phone_number}
                    onChange={handleInputChange}
                    placeholder="Enter your phone number"
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Gender</Form.Label>
                  <div className="d-flex">
                    <Form.Check
                      type="radio"
                      id="male"
                      name="gender"
                      value="male"
                      checked={formData.gender === "male"}
                      onChange={handleInputChange}
                      label={
                        <span>
                          <ArrowUp size={16} className="me-1 text-primary" /> Male
                        </span>
                      }
                      className="me-3"
                    />
                    <Form.Check
                      type="radio"
                      id="female"
                      name="gender"
                      value="female"
                      checked={formData.gender === "female"}
                      onChange={handleInputChange}
                      label={
                        <span>
                          <ArrowDown size={16} className="me-1 text-danger" /> Female
                        </span>
                      }
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <h4 className="mb-3 mt-4">Emergency Contact</h4>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <Phone size={16} className="me-2" />
                    Emergency Phone Number
                  </Form.Label>
                  <Form.Control
                    type="tel"
                    name="emergency_phone_number"
                    value={formData.emergency_phone_number}
                    onChange={handleInputChange}
                    placeholder="Emergency contact phone"
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <Users size={16} className="me-2" />
                    Relationship
                  </Form.Label>
                  <Form.Select
                    name="emergency_contact_relationship"
                    value={formData.emergency_contact_relationship}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select relationship</option>
                    <option value="Parent">Parent</option>
                    <option value="Guardian">Guardian</option>
                    <option value="Sibling">Sibling</option>
                    <option value="Spouse">Spouse</option>
                    <option value="Other">Other</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <Phone size={16} className="me-2" />
                    Sponsor Phone
                    <h6 className="small text-muted">!One who pays your rent </h6>
                  </Form.Label>
                  <Form.Control
                    type="tel"
                    name="sponsor_phone"
                    value={formData.sponsor_phone}
                    onChange={handleInputChange}
                    placeholder="Sponsor's phone number"
                  />
                </Form.Group>
              </Col>
            </Row>

            <h4 className="mb-3 mt-4">Academic Information</h4>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <School size={16} className="me-2" />
                    Registration Number
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="registration_number"
                    value={formData.registration_number}
                    onChange={handleInputChange}
                    placeholder="Your student registration number"
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <School size={16} className="me-2" />
                    Year of Study
                  </Form.Label>
                  <Form.Select
                    name="year_of_study"
                    value={formData.year_of_study}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="1">Year 1</option>
                    <option value="2">Year 2</option>
                    <option value="3">Year 3</option>
                    <option value="4">Year 4</option>
                    <option value="5">Year 5</option>
                    <option value="6">Year 6</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <School size={16} className="me-2" />
                    College
                  </Form.Label>
                  <Form.Select
                    name="college"
                    value={formData.college}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select College</option>
                    {colleges.map(college => (
                      <option key={college.id} value={college.id}>
                        {college.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <School size={16} className="me-2" />
                    Faculty
                  </Form.Label>
                  <Form.Select
                    name="faculty"
                    value={formData.faculty}
                    onChange={handleInputChange}
                    required
                    disabled={!formData.college || loadingFaculties}
                  >
                    {loadingFaculties ? (
                      <option>Loading faculties...</option>
                    ) : (
                      <>
                        <option value="">Select Faculty</option>
                        {faculties.map(faculty => (
                          <option key={faculty.id} value={faculty.id}>
                            {faculty.name}
                          </option>
                        ))}
                      </>
                    )}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <h4 className="mb-3 mt-4">Accommodation Details</h4>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <Home size={16} className="me-2" />
                    Room
                  </Form.Label>
                  <Form.Select
                    name="room"
                    value={formData.room}
                    onChange={handleInputChange}
                    required
                    disabled={loadingRooms}
                  >
                    {loadingRooms ? (
                      <option>Loading rooms...</option>
                    ) : (
                      <>
                        <option value="">Select Room</option>
                        {rooms.map(room => (
                          <option key={room.id} value={room.id}>
                            {`Room ${room.room_number}`}
                          </option>
                        ))}
                      </>
                    )}
                  </Form.Select>
                  {selectedRoomRate && (
                    <div className="mt-2">
                      <span className="badge bg-success">
                        Rate: KSh {selectedRoomRate.toLocaleString()}
                      </span>
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <Calendar size={16} className="me-2" />
                    Start Date
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="start_date"
                    value={formData.start_date}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <Calendar size={16} className="me-2" />
                    End Date
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="end_date"
                    value={formData.end_date}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <h4 className="mb-3 mt-4">Documents</h4>
            <Row className="mb-4">
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <Image size={16} className="me-2" />
                    Your Photo
                  </Form.Label>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleFileChange(e, 'photo')}
                  />
                  {photoPreview && (
                    <div className="mt-2">
                      <img
                        src={photoPreview}
                        alt="Preview"
                        style={{ height: '100px', objectFit: 'cover' }}
                        className="border rounded"
                      />
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <CreditCard size={16} className="me-2" />
                    ID Photo
                  </Form.Label>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleFileChange(e, 'id_photo')}
                  />
                  {idPhotoPreview && (
                    <div className="mt-2">
                      <img
                        src={idPhotoPreview}
                        alt="ID Preview"
                        style={{ height: '100px', objectFit: 'cover' }}
                        className="border rounded"
                      />
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <h4 className="mb-3 mt-4">Agreement</h4>
            <Row className="mb-3">
              <Col>
                <Form.Group className="mb-3">
                  <Form.Check
                    type="checkbox"
                    id="terms"
                    name="terms"
                    checked={formData.terms}
                    onChange={(e) => setFormData({ ...formData, terms: e.target.checked })}
                    label={
                      <span>
                        I agree to the{' '}
                        <a
                          href="https://sunshine.pythonanywhere.com/onboard/terms/" // Replace with your actual terms page URL
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: 'underline' }}
                        >
                          terms and conditions
                        </a>
                      </span>
                    }
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <Button
                variant="secondary"
                type="button"
                className="me-md-2"
                onClick={() => window.history.back()}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2"
                    />
                    Submitting...
                  </>
                ) : 'Submit Registration'}
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default OnboardingForm;