export const companyInfo = {
    name: "Sunshine Eco Homes.",
    description: "Providing affordable student accommodation.",
    products: [
      {
        name: "CloudManager Pro",
        description: "Enterprise-grade cloud management platform with advanced security features.",
        price: "$99/month per user"
      },
      {
        name: "DataAnalyzer",
        description: "Business intelligence tool for transforming data into actionable insights.",
        price: "$75/month per user"
      },
      {
        name: "SecureConnect",
        description: "End-to-end encrypted communication platform for teams.",
        price: "$50/month per user"
      }
    ],
    services: [
      {
        name: "Wifi and Internet Services",
        description: "Tenants of sunshine residence can access the internet and wifi services through the company's WFIF Sunshinehostel.",
        pricing: "ksh 50 for 2gb valid for 2 days, ksh 100 for 5gb valid for 3 days, ksh 650 for 10gb valid for 30 days"
      },
      {
        name: "Laundry Services",
        description: "Coming soon.",
        pricing: "Expected to start as low as ksh 100 per ndoo of dirty clothes"
      },
      {
        name: "Technical Support",
        description: "24/7 technical assistance for all our products and services.",
        pricing: "Included with product subscription"
      }
    ],
    faq: [
        {
            question: "Hello!",
            answer: "Hello! Welcome to Sunshine eco homes, how can i help you today?"
          },
      {
        question: "Who are you?",
        answer: "I am Zico, Sunshine's virtual assistant."
      },
      {
        question: "Can I get free voucher?",
        answer: "Yes, we offer a free voucher for new tenants as well as trustee clients."
      },
      {
        question: "Why is there no hot water?",
        answer: "We are currently working on the issue. Please bear with us."
      },
      {
        question: "Why is Maina so rude?",
        answer: "He is overly concerned about your wellfare and may come of as overbearing. Please understand"
      }
    ],
    contactInfo: {
      email: "sunshinehostbondo@gmail.com",
      phone: "Maina: 0725884205, Jafeth: 0748439084",
      address: "100m to Jaramogi Ogingá Odinga University, Bondo, Siaya"
    },
    hours: "Monday to Friday, 8 AM - 8 PM EST, weekend support available although it is not gauranteed."
  };