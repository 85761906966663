// ChatbotPlugin.js
import React, { useState, useEffect, useRef } from 'react';
import './ChatbotPlugin.css';
import { companyInfo } from './companyInfo';
const ChatbotPlugin = ({
  position = { bottom: '20px', right: '20px' },
  theme = {
    primary: '#265203', // Google blue
    secondary: '#0ae745', // Google green
    background: '#ffffff',
    text: '#181a1f'
  },
  //   apiKey = process.env.REACT_APP_GEMINI_API_KEY,
  apiKey = 'AIzaSyDpXR6y1BrBS8Bb-eNjIXGobHkNlG8D1JM',
  welcomeMessage = "Hello! I am Zico, how can I help you today?",
  botName = "Sunshine assistant",
  placeholder = "Type your message...",
  maxHeight = "500px"
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);

  // Initialize chat with welcome message
  useEffect(() => {
    setMessages([
      {
        text: welcomeMessage,
        sender: 'bot',
        timestamp: new Date().toISOString()
      }
    ]);
  }, [welcomeMessage]);

  // Scroll to bottom of chat when new messages arrive
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  // Handle window resize for responsiveness
  useEffect(() => {
    const handleResize = () => {
      if (chatContainerRef.current) {
        // Adjust max-height for smaller screens
        if (window.innerWidth < 768) {
          chatContainerRef.current.style.maxHeight = '70vh';
        } else {
          chatContainerRef.current.style.maxHeight = maxHeight;
        }
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call once on mount

    return () => window.removeEventListener('resize', handleResize);
  }, [maxHeight]);

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };


  const getCompanyInfoResponse = (query) => {
    const queryLower = query.toLowerCase();

    // Check for company information patterns
    if (queryLower.includes('contact') || queryLower.includes('reach') || queryLower.includes('email') || queryLower.includes('phone')) {
      return `You can contact ${companyInfo.name} via:
Email: ${companyInfo.contactInfo.email}
Phone: ${companyInfo.contactInfo.phone}
Address: ${companyInfo.contactInfo.address}`;
    }

    if (queryLower.includes('hours') || queryLower.includes('open') || queryLower.includes('when') && queryLower.includes('available')) {
      return `Our business hours are: ${companyInfo.hours}`;
    }
    if (queryLower.includes('sijapata code') || queryLower.includes("haven't received") || queryLower.includes('nimelipa wifi') || queryLower.includes('I have paid')) {
      return `Sorry about that, Please respond with the mpesa reference number only.`;
    }

    if (queryLower.includes('about') || queryLower.includes('company')) {
      return `${companyInfo.name} is ${companyInfo.description}`;
    }

    // Check for FAQs
    if (companyInfo.faq && companyInfo.faq.length > 0) {
      for (const item of companyInfo.faq) {
        // Check if the question keywords are in the query
        const questionKeywords = item.question.toLowerCase().split(' ').filter(word => word.length > 3);
        const matchCount = questionKeywords.filter(keyword => queryLower.includes(keyword)).length;

        // If the query matches most of the keywords in a FAQ question
        if (matchCount > questionKeywords.length * 0.6) {
          return item.answer;
        }
      }
    }

    // Check for product information
    if ((queryLower.includes('product') || queryLower.includes('offer') || queryLower.includes('sell')) && companyInfo.products.length > 0) {
      // Check for specific product queries
      for (const product of companyInfo.products) {
        if (queryLower.includes(product.name.toLowerCase())) {
          return `${product.name}: ${product.description}. Price: ${product.price || 'Contact us for pricing'}`;
        }
      }

      // General product inquiry
      return `Our products include: ${companyInfo.products.map(p => p.name).join(', ')}. Would you like to know more about a specific product?`;
    }

    // Check for service information
    if ((queryLower.includes('service') || queryLower.includes('help') || queryLower.includes('support')) && companyInfo.services.length > 0) {
      // Check for specific service queries
      for (const service of companyInfo.services) {
        if (queryLower.includes(service.name.toLowerCase())) {
          return `${service.name}: ${service.description}. ${service.pricing ? `Pricing: ${service.pricing}` : 'Contact us for pricing information.'}`;
        }
      }

      // General service inquiry
      return `Our services include: ${companyInfo.services.map(s => s.name).join(', ')}. Would you like to know more about a specific service?`;
    }

    // No direct match found, will use Gemini API
    return null;
  };
  const sendMessage = async () => {
    if (!inputText.trim()) return;

    // Add user message to chat
    const userMessage = {
      text: inputText,
      sender: 'user',
      timestamp: new Date().toISOString()
    };

    setMessages(prev => [...prev, userMessage]);
    setInputText('');
    setIsLoading(true);

    try {
      // First check if we can answer from company info
      const companyResponse = getCompanyInfoResponse(inputText);
      // Call Gemini API
      let responseText;
      if (companyResponse) {
        // Use the company info response
        responseText = companyResponse;
      } else {
        // Fall back to Gemini API with company context
        responseText = await generateGeminiResponse(inputText);
      }
      // Add bot response to chat
      const botMessage = {
        text: responseText,
        sender: 'bot',
        timestamp: new Date().toISOString()
      };

      setMessages(prev => [...prev, botMessage]);
    } catch (error) {
      console.error('Error generating response:', error);

      // Add error message
      const errorMessage = {
        text: "I'm currently unavailable, please call 0725884205 to speak to a human.",
        sender: 'bot',
        timestamp: new Date().toISOString(),
        isError: true
      };

      setMessages(prev => [...prev, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to call Gemini API
  const generateGeminiResponse = async (prompt) => {
    if (!apiKey) {
      console.error('Gemini API key is missing');
      return "API key is missing. Please provide a valid Gemini API key.";
    }

    const contextEnrichedPrompt = `As a customer service chatbot for ${companyInfo.name}, which is ${companyInfo.description}, please respond to the following customer query. 
    
Company information:
- Company Name: ${companyInfo.name}
- Contact Email: ${companyInfo.contactInfo.email}
- Contact Phone: ${companyInfo.contactInfo.phone}
- Business Hours: ${companyInfo.hours}
${companyInfo.services.length > 0 ? `- Services: ${JSON.stringify(companyInfo.services)}` : ''}
${companyInfo.faq.length > 0 ? `- FAQ: ${JSON.stringify(companyInfo.faq)}` : ''}

Customer query: ${prompt}

Please provide a helpful, and very brief response based on the company information. If you don't know the answer, suggest they contact us directly.`;
    // Gemini API endpoint
    const endpoint = "https://generativelanguage.googleapis.com/v1beta/models/gemini-2.0-flash:generateContent";

    try {
      const response = await fetch(`${endpoint}?key=${apiKey}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          contents: [{
            parts: [{
              text: contextEnrichedPrompt
            }]
          }]
        })
      });

      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }

      const data = await response.json();

      // Extract the generated text from Gemini's response
      return data.candidates[0].content.parts[0].text;
    } catch (error) {
      console.error('Error calling Gemini API:', error);
      throw error;
    }
  };

  return (
    <div className="chatbot-container" style={position}>
      {/* Chatbot toggle button */}
      <button
        className="chatbot-toggle"
        onClick={toggleChat}
        style={{ backgroundColor: theme.primary }}
      >
        {isOpen ? (
          <span>✕</span>
        ) : (
          <span>💬</span>
        )}
      </button>

      {/* Chatbot dialog */}
      {isOpen && (
        <div
          className="chatbot-dialog"
          style={{
            backgroundColor: theme.background,
            color: theme.text,
            borderColor: theme.primary
          }}
        >
          {/* Header */}
          <div
            className="chatbot-header"
            style={{ backgroundColor: theme.primary, color: '#fff' }}
          >
            <h3>{botName}</h3>
          </div>

          {/* Messages container */}
          <div
            className="chatbot-messages"
            ref={chatContainerRef}
          >
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`chatbot-message ${msg.sender === 'bot' ? 'bot-message' : 'user-message'} ${msg.isError ? 'error-message' : ''}`}
                style={{
                  backgroundColor: msg.sender === 'bot' ? '#f1f3f4' : theme.primary,
                  color: msg.sender === 'bot' ? theme.text : '#fff'
                }}
              >
                <div className="message-text">{msg.text}</div>
                <div className="message-timestamp">
                  {new Date(msg.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                </div>
              </div>
            ))}
            {isLoading && (
              <div className="chatbot-message bot-message loading-message">
                <div className="typing-indicator">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>

          {/* Input area */}
          <div className="chatbot-input">
            <textarea
              value={inputText}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              placeholder={placeholder}
              rows={1}
              style={{ borderColor: theme.primary }}
            />
            <button
              onClick={sendMessage}
              disabled={isLoading || !inputText.trim()}
              style={{
                backgroundColor: theme.primary,
                opacity: (isLoading || !inputText.trim()) ? 0.6 : 1
              }}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" fill="white" />
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatbotPlugin;